import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'
import moment from 'moment'

// Notification

export default function useReceiptList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refReceiptsTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'trxID',
      label: 'Receipt/file No.',
      sortable: true,
    },
    {
      key: 'nvsFileType',
      label: 'Product Description',
      sortable: true,
    },
    {
      key: 'purchasers',
      label: 'customer',
      sortable: false,
    },
    {
      key: 'status',
      label: 'Receipt Status',
      sortable: true,
    },
    {
      key: 'paymentMode',
      label: 'Payment mode',
      sortable: true,
    },
    {
      key: 'trxDate',
      label: 'transaction date',
      sortable: true,
    },
    {
      key: 'remarks',
      label: 'Remarks',
      sortable: false,
    },
    {
      key: 'createdAt',
      label: 'Created By',
      sortable: true,
    },
    { key: 'actions' },
  ]
  const perPage = ref(route.query.perPage ?? process.env.VUE_APP_PER_PAGE_CONTENT)
  const totalReceipts = ref(0)
  const currentPage = ref(route.query.page ?? 1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(route.query.search ?? '')
  const fileNo = ref(route.query.fileNo ?? '')
  const receiptNo = ref(route.query.receiptNo ?? '')
  const nric = ref(route.query.nric ?? '')
  const lotNo = ref(route.query.lotNo ?? '')
  const createdBy = ref(route.query.createdBy ?? '')
  const sortBy = ref(route.query.sortBy ?? 'trxID')
  const todaysTransaction = ref(true)
  const isSortDirDesc = ref(!((route.query.sortDesc && route.query.sortDesc === 'true')))
  const fileTypeFilter = ref([])
  if (Array.isArray(route.query.fileType)) {
    fileTypeFilter.value = route.query.fileType
  } else if (route.query.fileType) {
    fileTypeFilter.value = [route.query.fileType]
  }
  const receiptStatusFilter = ref([])
  if (Array.isArray(route.query.receiptStatus)) {
    receiptStatusFilter.value = route.query.receiptStatus
  } else if (route.query.receiptStatus) {
    receiptStatusFilter.value = [route.query.receiptStatus]
  }
  const paymentModeFilter = ref([])
  if (Array.isArray(route.query.paymentMode)) {
    paymentModeFilter.value = route.query.paymentMode
  } else if (route.query.paymentMode) {
    paymentModeFilter.value = [route.query.paymentMode]
  }
  const receiptsData = ref([])
  const trxDateFilter = ref(route.query.trxDate || '')
  const customerFilter = ref(route.query.customer || '')
  let initialLoad = true

  const dataMeta = computed(() => {
    const localItemsCount = refReceiptsTable.value ? refReceiptsTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalReceipts.value,
    }
  })

  const refetchData = () => {
    refReceiptsTable.value.refresh()
  }

  const fetchReceipts = () => {
    let trxDateFilterVal = ''
    if (trxDateFilter.value) {
      trxDateFilterVal = trxDateFilter.value
      todaysTransaction.value = false
    } else if (todaysTransaction.value) {
      trxDateFilterVal = moment().format('DD/MM/YYYY')
    }
    store
      .dispatch('app-all-receipts/fetchReceipts', {
        search: searchQuery.value,
        fileNo: fileNo.value,
        receiptNo: receiptNo.value,
        nric: nric.value,
        lotNo: lotNo.value,
        createdBy: createdBy.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        receiptStatus: receiptStatusFilter.value,
        fileType: fileTypeFilter.value,
        paymentMode: paymentModeFilter.value,
        trxDate: trxDateFilterVal,
        customer: customerFilter.value,
      },
      { root: true })
      .then(response => {
        const { receipts, totalReceipts: totalReceiptsCount } = response.data
        receiptsData.value = receipts
        // callback(receipts)
        totalReceipts.value = totalReceiptsCount
        if (!initialLoad) {
          router.replace({
            query: {
              search: searchQuery.value,
              fileNo: fileNo.value,
              receiptNo: receiptNo.value,
              nric: nric.value,
              lotNo: lotNo.value,
              createdBy: createdBy.value,
              perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
              receiptStatus: receiptStatusFilter.value,
              fileType: fileTypeFilter.value,
              paymentMode: paymentModeFilter.value,
              trxDate: trxDateFilter.value,
              customer: customerFilter.value,
            },
          }).catch(() => {})
        }
        initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  let timer = null
  watch([fileTypeFilter, receiptStatusFilter, paymentModeFilter, trxDateFilter, todaysTransaction, customerFilter], () => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      currentPage.value = 1
      fetchReceipts()
    }, 1000)
  }, { deep: true })

  watch([searchQuery], (current, prev) => {
    const currentVal = current[0]
    const prevVal = prev[0]
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      if ((!currentVal && prevVal) || (currentVal && currentVal.length > 2)) {
        currentPage.value = 1
        fetchReceipts()
      }
    }, 1000)
  }, { deep: true })

  watch([fileNo], (current, prev) => {
    const currentVal = current[0]
    const prevVal = prev[0]
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      if ((!currentVal && prevVal) || (currentVal && currentVal.length > 2)) {
        currentPage.value = 1
        fetchReceipts()
      }
    }, 1000)
  }, { deep: true })

  watch([nric], (current, prev) => {
    const currentVal = current[0]
    const prevVal = prev[0]
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      if ((!currentVal && prevVal) || (currentVal && currentVal.length > 2)) {
        currentPage.value = 1
        fetchReceipts()
      }
    }, 1000)
  }, { deep: true })

  watch([receiptNo], (current, prev) => {
    const currentVal = current[0]
    const prevVal = prev[0]
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      if ((!currentVal && prevVal) || (currentVal && currentVal.length > 2)) {
        currentPage.value = 1
        fetchReceipts()
      }
    }, 1000)
  }, { deep: true })

  watch([lotNo], (current, prev) => {
    const currentVal = current[0]
    const prevVal = prev[0]
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      if ((!currentVal && prevVal) || (currentVal && currentVal.length > 2)) {
        currentPage.value = 1
        fetchReceipts()
      }
    }, 1000)
  }, { deep: true })

  watch([createdBy], (current, prev) => {
    const currentVal = current[0]
    const prevVal = prev[0]
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      if ((!currentVal && prevVal) || (currentVal && currentVal.length > 2)) {
        currentPage.value = 1
        fetchReceipts()
      }
    }, 1000)
  }, { deep: true })

  watch([currentPage], () => {
    fetchReceipts()
  }, { deep: true })

  watch([sortBy], () => {
    fetchReceipts()
  }, { deep: true })

  watch([isSortDirDesc], () => {
    fetchReceipts()
  }, { deep: true })

  fetchReceipts()

  return {
    fetchReceipts,
    tableColumns,
    perPage,
    currentPage,
    totalReceipts,
    dataMeta,
    perPageOptions,
    searchQuery,
    fileNo,
    receiptNo,
    nric,
    lotNo,
    createdBy,
    sortBy,
    isSortDirDesc,
    refReceiptsTable,
    refetchData,
    receiptsData,

    // Extra Filters
    fileTypeFilter,
    receiptStatusFilter,
    paymentModeFilter,
    trxDateFilter,
    customerFilter,
    todaysTransaction,
  }
}
